import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { InitAccountService } from '@services/initAccount.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';

    public recoverPasswordForm: UntypedFormGroup;
    public isAuthLoading = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private initAccountService: InitAccountService,
        private auth: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page-ex'
        );
        this.recoverPasswordForm = new UntypedFormGroup({
            password: new UntypedFormControl(null, Validators.required),
            confirmPassword: new UntypedFormControl(null, Validators.required)
        });
    }

    recoverPassword() {
        if (this.recoverPasswordForm.valid && (this.recoverPasswordForm.controls['password'].value == this.recoverPasswordForm.controls['confirmPassword'].value)) {

            this.initAccountService.initAccountChangePasword(this.auth.tempUser.token, this.recoverPasswordForm.controls['password'].value).subscribe({
                next: (data: any) => {
                    console.log(data)
                    if (data.success) {
                        this.auth.tempUser=null
                        this.auth.setAuth(data)
                        this.router.navigateByUrl("/dashboard");
                    }
                },
                error: err => {
                    this.toastr.error(err.error.error);
                }
            });
        

        } else {
            this.toastr.error('Invalid form', 'Invalid form! Verify that field Password and Confirm Password are the same');
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page-ex'
        );
    }
}
