import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { CrmapiService } from '@services/crmapi.service';
import { DeviceobsapiService } from '@services/deviceobsapi.service';
import { DeviceapiService } from '@services/deviceapi.service';
import { Chart, registerables } from 'chart.js';
import {DateTime} from 'luxon';

 
@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  chart: any = [];
  sensValue: any;
  sensName: any;

  public obsCount = 0;
  public devicesObs : any[];
  //public id: string;
  public internalID = "";
  public deviceInfo : any[];

  public devname  = "";
  public devtype  = "";
  public devdescription = "";

  constructor(private router: Router,private route: ActivatedRoute,private appService: AppService, private api : ApiService,private devobsapi : DeviceobsapiService, private crmapi : CrmapiService, private deviceapi : DeviceapiService) {

    //Chart.register(...registerables);
  }
  public profileData : any;

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd LLL yyyy');
}

  ngOnInit(): void {

      let profileData = {};
      let crmProfile = {};
     // let devicesInfo = {};

     const allParams = this.route.snapshot.params;
     const id = allParams.id; 

     console.log("dev id "+id);

      //let stringifiedData = {};

      this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
        this.profileData = profileData;
       
        //console.log(profileData["internalID"]);
        //JSON.parse(profileData)
        //this.user = data;
        this.internalID = profileData["internalID"];

        //this.internalID = "5ac40df4-37d9-acf3-35fb-6284df3e0658";

        this.deviceapi.checkUserAccountDevices(id).subscribe((devicedata: any[])=>{
         console.log("ua done"+id);
         
        //stringifiedData = JSON.stringify(devicesdata);
       // var count = Object.keys(devicesdata).length;

        this.deviceInfo = devicedata;//JSON.stringify(devicesdata);
        //$scope.devicesInfo = devicesdata;

        console.log(this.deviceInfo );
        this.devname = devicedata["name"];
        this.devtype = devicedata["type"];
        this.devdescription = devicedata["description"];
 
  
        //get devices info


        //get observations of the device
        this.devobsapi.getObservationsByReporter(this.devname).subscribe((deviceobs: any[])=>{
        //console.log(deviceobs);
        this.devicesObs = deviceobs;
        this.obsCount = Object.keys(deviceobs).length;

        //var latest = deviceobs[Object.keys(deviceobs).length-1];
        //console.log(latest);

        //bind data for chart
        this.sensValue = deviceobs.map((vals: any) => vals.data_value);
      this.sensName = deviceobs.map((vals: any) => vals.name + ' '+ this.formatDate(vals.created_at));
        //end bind data chart
       // console.log(this.sensValue);
       this.chart = new Chart('canvas', {
        type: 'bar',
        data: {
          labels: this.sensName,
          datasets: [
            {
              data: this.sensValue,
              borderColor: '#3e95cd',
              
              label: 'Value',
              backgroundColor: 'rgba(93, 175, 89, 0.1)',
              borderWidth: 3,
            },
          ],
        },
      });



        })
        //end get infos

         
  
  
        
      })


      })

       
       


  }

}
