import { Component, OnInit } from '@angular/core';

import { JsonpClientBackend } from '@angular/common/http';

import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { PlantcycleService } from '@services/plantcycle.service';


import { CsvDataService } from '@services/csv-data-service.service';



@Component({
  selector: 'app-plantcycle',
  templateUrl: './plantcycle.component.html',
  styleUrls: ['./plantcycle.component.scss']
})
export class PlantcycleComponent implements OnInit {public internalID = "";
public objectsInfo : any[];

constructor(private router: Router,private csvService :CsvDataService,private appService: AppService, private api : ApiService, private plantcycleService: PlantcycleService) { }
public profileData : any;

  ngOnInit(): void {

  let profileData = {};
    

    this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
           
      this.profileData = profileData;
             
      this.internalID = profileData["internalID"];
      

      this.plantcycleService.getObjects(this.internalID).subscribe((objData: any[])=>{
               
       
      //var count = Object.keys(objData).length;

      this.objectsInfo = objData;//JSON.stringify(objData);
      

      console.log(this.objectsInfo );


      //get info


      
    })


    })

    const btn: HTMLElement = document.getElementById('export');
    btn.addEventListener('click', () => {
      CsvDataService.exportToCsv('export.csv', this.objectsInfo);
    });
    //end logic
    

}

}
