import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {DevicesComponent} from '@pages/devices/devices/devices.component';
import {DeviceComponent} from '@pages/device/device/device.component';
import {NotesComponent} from '@pages/notes/notes.component';
import {NewnotesComponent} from '@pages/newnotes/newnotes.component';
import {CasesComponent} from '@pages/cases/cases.component';
import {InvoicesComponent} from '@pages/invoices/invoices.component';

import {DocsComponent} from '@pages/docs/docs.component';


import {InvoiceComponent} from '@pages/invoice/invoice.component';

import {DocComponent} from '@pages/doc/doc.component';

import {CaseformComponent} from '@pages/caseform/caseform.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {WaterdosageComponent} from '@pages/waterdosage/waterdosage.component';
import {WaterdosageaddComponent} from '@pages/waterdosageadd/waterdosageadd.component';

import {PlantcycleComponent} from '@pages/plantcycle/plantcycle.component';
import {PlantcycleaddComponent} from '@pages/plantcycleadd/plantcycleadd.component';

import {CostComponent} from '@pages/cost/cost.component';
import {CostaddComponent} from '@pages/costadd/costadd.component';


import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { ConfirmEmailComponent } from '@modules/confirm-email/confirm-email.component';
import { UserRegisteredComponent } from '@modules/user-registered/user-registered.component';
import { ResponseResetComponent } from '@modules/response-reset/response-reset.component';

import {UsertriggerComponent} from '@pages/usertrigger/usertrigger.component';
import {UsertriggeraddComponent} from '@pages/usertriggeradd/usertriggeradd.component';
import { TwofactorComponent } from '@modules/twofactor/twofactor.component';
import { Co2EconomyCalculatorComponent } from '@pages/co2-economy-calculator/co2-economy-calculator.component';
import { SurfaceEconomyCalculatorComponent } from '@pages/surface-economy-calculator/surface-economy-calculator.component';
import { WaterEconomyCalculatorComponent } from '@pages/water-economy-calculator/water-economy-calculator.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'devices',
                component: DevicesComponent
            },
            {
                path: 'notes',
                component: NotesComponent
            },
            {
                path: 'newnotes',
                component: NewnotesComponent
            },
            {
                path: 'cases',
                component: CasesComponent
            },
            {
                path: 'invoices',
                component: InvoicesComponent
            },
            {
                path: 'docs',
                component: DocsComponent
            },
            {
                path: 'invoice/:id',
                component: InvoiceComponent
            },
            {
                path: 'doc/:id',
                component: DocComponent
            },
            {
                path: 'caseform',
                component: CaseformComponent
            },
            {
                path: 'device/:id',
                component: DeviceComponent
            },
            {
                path: 'waterdosage',
                component: WaterdosageComponent
            },
            {
                path: 'waterdosageadd',
                component: WaterdosageaddComponent
            },
			{
                path: 'usertrigger',
                component: UsertriggerComponent
            },
            {
                path: 'usertriggeradd',
                component: UsertriggeraddComponent
            },
            {
                path: 'plantcycle',
                component: PlantcycleComponent
            },
            {
                path: 'plantcycleadd',
                component: PlantcycleaddComponent
            },
            {
                path: 'cost',
                component: CostComponent
            },
            {
                path: 'costadd',
                component: CostaddComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: 'surface-economy',
                component: SurfaceEconomyCalculatorComponent
            },
            {
                path: 'water-economy',
                component: WaterEconomyCalculatorComponent
            },
            {
                path: 'co2-economy',
                component: Co2EconomyCalculatorComponent
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    // {
    //     path: 'register',
    //     component: RegisterComponent,
    //     canActivate: [NonAuthGuard]
    // },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'init',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'twofactor',
        component: TwofactorComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'user-registered',
        component: UserRegisteredComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'confirm-email/:token',
        component: ConfirmEmailComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'response-reset-password/:token',
        component: ResponseResetComponent
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
