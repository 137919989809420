import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-co2-economy-calculator',
  templateUrl: './co2-economy-calculator.component.html',
  styleUrls: ['./co2-economy-calculator.component.scss']
})
export class Co2EconomyCalculatorComponent implements OnInit {

  economy: any
  new_surface: any

  co2EconomyForm = new FormGroup({
    surface: new FormControl('', [Validators.required]),

  });

  constructor() { }

  ngOnInit(): void {
  }



  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  calculate() {
    let economy = (parseFloat(this.co2EconomyForm.controls.surface.value) * 374 * 0.0042).toFixed(2)
    this.economy = `${economy} kg`


  }


}
