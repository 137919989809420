<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profile</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">User Profile</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Image -->
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <img
                                class="profile-user-img img-fluid img-circle"
                                src="assets/img/default-profile.png"
                                alt="User"
                            />
                        </div>

                        <h3 class="profile-username text-center">
                            {{ user.firstName }} {{ user.lastName }}
                            
                        </h3>

                        <p class="text-muted text-center">GreenhouseIoT</p>

                        <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>Enrolled Devices</b>
                                <a class="float-right">{{devicesCount}}</a>
                            </li>
                             
                        </ul>

                        <a routerLink="/devices" class="btn btn-primary btn-block"
                            ><b>Devices</b></a
                        >
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->

                <!-- About Me Box -->
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Details</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <strong
                            ><i class="fas fa-book mr-1"></i> {{account_type}}</strong
                        >
   
                        <p class="text-muted">
                            {{crmAccountName}}
                            <br/>
                            {{crmUsername}}
                        </p>

                        <hr />

                        <strong
                            ><i class="fas fa-map-marker-alt mr-1"></i>
                            Locatie</strong
                        >

                        <p class="text-muted"> {{crmCity}}</p>

                        <hr />

                        <strong
                            ><i class="fas fa-pencil-alt mr-1"></i>
                            Billing</strong
                        >

                        <p class="text-muted">
                            {{billing_address_city}}
                            <br/>
                            {{billing_address_state}}
                            <br/>
                            {{billing_address_street}}
                        </p>

                        <hr />

                        <strong
                            ><i class="far fa-file-alt mr-1"></i> Shipping</strong
                        >

                        <p class="text-muted">
                            {{shipping_address_city}}
                            <br/>
                            {{shipping_address_state}}
                            <br/>
                            {{shipping_address_street}}
                        </p>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a
                                    class="nav-link active"
                                    href="/profile#activity"
                                    data-toggle="tab"
                                    >Account Notes</a
                                >
                            </li>
                             
                        </ul>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="active tab-pane" id="activity">
                                <ul style="list-style-type: none;">
                                    <li *ngFor="let note of notesInfo">
                                <!-- Post -->
                                <div class="post">
                                    <div class="user-block">
                                        <img
                                            class="img-circle img-bordered-sm"
                                            src="assets/img/logo.png"
                                            alt="user image"
                                        />
                                        <span class="username">
                                            <a routerLink="/profile/#">{{note.name}}</a>
                                            <a
                                                href="/profile/#"
                                                class="float-right btn-tool"
                                                ><i class="fas fa-times"></i
                                            ></a>
                                        </span>
                                        <span class="description"
                                            >{{note.date_entered}}</span
                                        >
                                    </div>
                                    <!-- /.user-block -->
                                    <p>
                                        {{note.description}}
                                    </p>

                                     

                                     
                                </div>
                                <!-- /.post -->
                                <hr />
                                </li></ul>

                                 
                            </div>
                            <!-- /.tab-pane -->
                             
 

                            
                            <!-- /.tab-pane -->
                        </div>
                        <!-- /.tab-content -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.nav-tabs-custom -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
