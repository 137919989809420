<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Costs</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Costs</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Cost elements</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                    >
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="objNewForm" (ngSubmit)="onSubmit()">
                    <div class="input-group mb-3">
                        <input
                            formControlName="waterConsumption"
                            type="number"
                            class="form-control"
                            placeholder="Water Consumption [previous month, cubic meters]"
                        />
                    </div>
                    <div class="input-group mb-3">
                        <input
                            formControlName="seedTypeName"
                            type="text"
                            class="form-control"
                            placeholder="Seed Type"
                        />
                    </div>
                    <div class="input-group mb-3">
                        <input
                            formControlName="seedAcquisitionDate"
                            type="text"
                            pattern="\d{1,2}/\d{1,2}/\d{4}" 
                            class="form-control"
                            placeholder="Acquisition Date [dd/mm/yyyy]"
                        />
                    </div>
                    <div class="input-group mb-3">
                        <input
                            formControlName="seedAcquisitionQuantity"
                            type="number"
                            class="form-control"
                            placeholder="Acquisition Quantity [kg]"
                        />
                    </div>
                    <div class="input-group mb-3">
                        <input
                            formControlName="seedAcquisitionPrice"
                            type="number"
                            class="form-control"
                            placeholder="Acquisition Price"
                        />
                    </div>
 
                    
                    <div class="row">
                        
                        <!-- /.col -->
                        <div class="col-5">
                            <app-button
                                [type]="'submit'"
                                [block]="true"
                                [loading]="isAuthLoading"
                                
                            >
                                Save
                            </app-button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

            </div>
            <!-- /.card-body -->
            <div class="card-footer"></div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
