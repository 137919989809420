<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Costs</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Costs</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Costs elements</h3>
                
                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                    >
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                
                <table class="table table-responsive">
                    <tr>
                      <th>Water Consumption</th>
                      <th>Typename</th>
                      <th>Seed Acquisition Date</th>
                      <th>Seed Acquisition Quantity</th>
                      <th>Seed Acquisition Price</th>
                     </tr>
                    <tr *ngFor="let dv of objectsInfo">
                        <td>{{dv.waterConsumption}}</td>
                        <td>{{dv.seedTypeName}}</td>
                        <td>{{dv.seedAcquisitionDate}}</td>
                        <td>{{dv.seedAcquisitionQuantity}}</td>
                        <td>{{dv.seedAcquisitionPrice}}</td>
                       </tr>
                    </table>

                     
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <div>
                    <button type="button" class="btn btn-primary btn-block" style="width:150px;" id="export">Export as CSV</button>
                </div>
            </div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
