import {
    Component,
    OnInit,
    Renderer2,
    OnDestroy,
    HostBinding
} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'register-box';

    public registerForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;

    submitted = false;
    errRegisterMsg = "";

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private authService: AuthService,
        private appService: AppService
    ) {}

    ngOnInit() {
        console.log('ngOnInit');
        this.renderer.addClass(
            document.querySelector('app-root'),
            'register-page-ex'
        );
        this.registerForm = new UntypedFormGroup({
            firstName: new UntypedFormControl(null, Validators.required),
            lastName: new UntypedFormControl(null, Validators.required),
            email: new UntypedFormControl(null, Validators.required),
            password: new UntypedFormControl(null, [Validators.required])
        });
    }

    onSubmit() {
        console.log(this.registerForm.value);
        this.submitted = true
        if (this.registerForm.valid) {
          this.authService
            .register(this.registerForm.value)
            .subscribe({
              next: data => {
                //this.router.navigateByUrl('/login');
                this.router.navigateByUrl('/user-registered');
              },
              error: err => {
                this.errRegisterMsg = err.error.error;
              }
            });
        }
      }

    

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page-ex'
        );
    }
}
