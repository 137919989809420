<app-dropdown class="user-menu">
    <ng-container dropdown-button>
        <img
            [src]="user.picture || 'assets/img/default-profile.png'"
            class="user-image img-circle elevation-2"
            alt="User Image"
        />
        <a routerLink="/newnotes" [hidden]="!bellVisible">
        <i class="far fa-bell"></i>
        <span class="badge badge-warning navbar-badge">{{notesCount}}</span></a>
    </ng-container>
    <ng-container dropdown-menu>
        <li class="user-header bg-primary">
            <img
                [src]="user.picture || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            />


            <p>
                <span>{{ userEmail }}</span>
                

                <small>
                    <a  [hidden]="!bellVisible"  href="#" (click)="dismissNewNotes()" >

                          
                        
                        <font color="white"><i class="far fa-bell"></i> Dismiss Notifications</font>
                    
                    </a>
                </small>

            </p>
        </li>
        <!-- Menu Body -->
        <!--
        <li class="user-body">
            <div class="row">
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Followers</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Sales</a>
                </div>
                <div class="col-4 text-center">
                    <a [routerLink]="['/']">Friends</a>
                </div>
            </div>
            
        </li>
    -->
     
        <!-- Menu Footer-->
        <li class="user-footer">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Sign out</a
            >
        </li>
    </ng-container>
</app-dropdown>
