import { Component, OnInit } from '@angular/core';

import { JsonpClientBackend } from '@angular/common/http';

import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { WaterdosageService } from '@services/waterdosage.service';

import { CsvDataService } from '@services/csv-data-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-waterdosage',
  templateUrl: './waterdosage.component.html',
  styleUrls: ['./waterdosage.component.scss']
})
export class WaterdosageComponent implements OnInit {

  public internalID = "";
  public objectsInfo = [];
  public apiKeyForm: FormGroup;
  rachio = null
  editingRachio: Boolean = false


  constructor(private toastr: ToastrService, private router: Router, private csvService: CsvDataService, private appService: AppService, private api: ApiService, private waterdosageservice: WaterdosageService) { }
  public profileData: any;

  ngOnInit(): void {

    this.apiKeyForm = new FormGroup({
      rachio: new FormControl(null, Validators.required),
    });




    this.api.getUserProfileAndInfo().subscribe((profileData: any) => {

      this.profileData = profileData;
      this.internalID = profileData["internalID"];
      this.rachio = profileData.rachioApiKey
      console.log(profileData.rachioApiKey)
      this.waterdosageservice.getObjects(this.internalID).subscribe((objData: any[]) => {


        //var count = Object.keys(objData).length;

        this.objectsInfo = objData;//JSON.stringify(objData);


        console.log(this.objectsInfo);

        this.apiKeyForm = new FormGroup({
          rachio: new FormControl(this.rachio, Validators.required),
        });

        //get info



      })


    })

    const btn: HTMLElement = document.getElementById('export');
    btn.addEventListener('click', () => {
      CsvDataService.exportToCsv('export.csv', this.objectsInfo);
    });
    //end logic


  }

  requestAdd() {
    console.log(this.apiKeyForm.value.rachio)
    if (this.apiKeyForm.value.rachio != null) {
      this.waterdosageservice.postApiKey(this.apiKeyForm.value.rachio, 'rachio').subscribe((response: any) => {
        console.log(response)
        if (response.success) {
          this.rachio = this.apiKeyForm.value.rachio
          this.editingRachio = false
        }
        else {
          console.log(response)
          this.toastr.error(response.message, 'Error');
        }

        this.apiKeyForm = new FormGroup({
          rachio: new FormControl(this.rachio, Validators.required),
        });

        //get info



      })
    }
  }

  requestEdit() {
    console.log(this.apiKeyForm.value.rachio)
    this.waterdosageservice.postApiKey(this.apiKeyForm.value.rachio, 'rachio').subscribe((response: any) => {
      console.log(response)
      if (response.success) {
        this.rachio = this.apiKeyForm.value.rachio
        this.editingRachio = false
      }
      else {
        console.log(response)
        this.toastr.error(response.message, 'Error');
      }

      this.apiKeyForm = new FormGroup({
        rachio: new FormControl(this.rachio, Validators.required),
      });

      //get info



    })

  }


  requestClear() {

    this.waterdosageservice.deleteApiKey('rachio').subscribe((response: any) => {
      console.log(response)
      if (response.success) {
        this.rachio = null
        this.editingRachio = false
      }
      else {
        console.log(response)
        this.toastr.error(response.message, 'Error');
      }

      this.apiKeyForm = new FormGroup({
        rachio: new FormControl(this.rachio, Validators.required),
      });

      //get info



    })

  }

  isReadOnly() {
    if (this.rachio && !this.editingRachio) {
      return true
    }
    else if (!this.rachio || this.rachio && this.editingRachio)
      return false
  }

  toggleEdit() {
    this.editingRachio = true
  }



  toggleCancel() {
    this.editingRachio = false
  }

}
