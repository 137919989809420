<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Account invoices</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Invoices</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Invoices</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                    >
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                    <ul style="list-style-type: none;">
                        <li *ngFor="let dv of devicesInfo">
                            <div class="col-lg-12 col-12">
                                <!-- small box -->
                                <div class="small-box bg-info">
                                    <div class="inner">
                                        <h3> {{dv.name}}</h3>
                                        <b> {{dv.total_amount | currency: 'RON'}}</b>
                                        <hr>
                                        {{dv.date_entered}}
                
                                        <p [innerHtml]="dv.description | htmlpipe">
                                        <hr>
                                        <div class="d-flex justify-content-between btn-lg"><span> {{dv.status}}</span> <button class="btn btn-success" (click)="this.paymentapi.makePayment(dv)" *ngIf="dv.status.toLowerCase()!='paid'">Pay Via Stripe</button>
</div>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-checkmark"></i>
                                    </div>
                                    
                                    <a routerLink="/invoice/{{dv.id}}" class="small-box-footer"
                                        >More info <i class="fas fa-arrow-circle-right"></i
                                    ></a>
                                </div>
                            </div>
                    
                        </li>
                    </ul>
            </div>
            <!-- /.card-body -->
            <div class="card-footer"></div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
