import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private appService: AppService, private auth: AuthService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.getProfile();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }
    

    async getProfile() {
        //console.log('oooo');
       // this.auth.
       //this.auth.getUserPayload();
       //console.log("get usr");
       
        //console.log(this.appService.user);
        //console.log("get usr");
        if (this.appService.user) {
            //console.log("true");
            //console.log(this.appService.user);
            //if (Object.keys(this.appService.user).length === 0)
            //  {
                //console.log("empty");
                //this.router.navigateByUrl("/login"); 
             // }

            return true;
        }
        //console.log("false");
        //if (this.appService.user == null) {
            //this.router.navigateByUrl("/login"); 
            //return false;
        //}
        

        try {
            //console.log('get profile');
            await this.appService.getProfile();
            //this.auth.getUserPayload();
            //console.log(this.appService.user == null);
            //console.log('end get profile');
            //if (Object.keys(this.appService.user).length === 0)
            //{
              //console.log("emptyAA");
              //this.router.navigateByUrl("/login"); 
            //}

            return true;
        } catch (error) {
            return false;
        }
    }
}
