import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { InitAccountService } from '@services/initAccount.service';
import { Router } from '@angular/router';
import { TfaService } from '@services/tfa.service';

@Component({
  selector: 'app-twofactor',
  templateUrl: './twofactor.component.html',
  styleUrls: ['./twofactor.component.scss']
})
export class TwofactorComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'login-box';

  public twofactorForm: UntypedFormGroup;
  public isAuthLoading = false;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private initAccountService: InitAccountService,
    private auth: AuthService,
    private router: Router,
    private tfa: TfaService
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page-ex'
    );
    this.twofactorForm = new UntypedFormGroup({
      otp: new UntypedFormControl(null, Validators.required),
    });
  }

  otpVerify() {
    if (this.twofactorForm.valid && (this.twofactorForm.controls['otp'].value == this.auth.otp)) {

      this.tfa.verifyTotp(this.twofactorForm.controls['otp'].value,this.auth.tempUser.token).subscribe({
        next: (data: any) => {
          console.log(data)
          if (data.success) {
            this.auth.setAuth(data)
            this.router.navigateByUrl("/dashboard");
          }
        },
        error: err => {
          this.toastr.error(err.error.error);
        }
      });


    } else {
      this.toastr.error('Invalid form', 'Invalid form! Verify that field Password and Confirm Password are the same');
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page-ex'
    );
  }
}
