import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostBinding
} from '@angular/core';
import {
HttpClient,
} from '@angular/common/http';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import {ToastrService} from 'ngx-toastr';

import { ApiService } from '@services/api.service';
import { PlantcycleService } from '@services/plantcycle.service';

@Component({
  selector: 'app-plantcycleadd',
  templateUrl: './plantcycleadd.component.html',
  styleUrls: ['./plantcycleadd.component.scss']
})
export class PlantcycleaddComponent implements OnInit {

  public objNewForm: UntypedFormGroup;

  public isAuthLoading = false;
    

    submitted = false;
    errRegisterMsg = "";

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private api : ApiService,
        private plantcycle: PlantcycleService,
        private authService: AuthService,
        private appService: AppService
    ) {}

  ngOnInit(): void {

    this.objNewForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      plantDay: new UntypedFormControl(null, Validators.required),
      plantTime: new UntypedFormControl(null, Validators.required) , 
      plantMassDry: new UntypedFormControl(null, Validators.required),
    plantMassWet: new UntypedFormControl(null, Validators.required),
    plantHarvestDay: new UntypedFormControl(null, Validators.required),
    plantHarvestTime: new UntypedFormControl(null, Validators.required),
    plantHarvestMass : new UntypedFormControl(null, Validators.required)
  });

  }

  onSubmit() {
    console.log(this.objNewForm.value);
 
    this.submitted = true;
        if (this.objNewForm.valid) {
          //get the account id , after - post data for new case
          this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
            //this.profileData = profileData;
            console.log("PD");
            //console.log(profileData["internalID"]);
            //JSON.parse(profileData)
            //this.user = data;
            const internalID = profileData["internalID"]; 
            console.log(internalID);
            this.plantcycle.postObject(internalID,this.objNewForm.value.name,this.objNewForm.value.plantDay,this.objNewForm.value.plantTime,this.objNewForm.value.plantMassDry,this.objNewForm.value.plantMassWet,this.objNewForm.value.plantHarvestDay,this.objNewForm.value.plantHarvestTime,this.objNewForm.value.plantHarvestMass).subscribe({
              next: data => {
                //this.router.navigateByUrl('/login');
                this.router.navigateByUrl('/plantcycle');
              },
              error: err => {
                //this.errRegisterMsg = err.error.error;
                this.router.navigateByUrl('/plantcycle');
              }
            });

          })
          this.router.navigateByUrl('/plantcycle');
        }
        else{
          this.toastr.error('Please specify required data', 'Invalid form');
        }
  }

}