import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {ToastrService} from 'ngx-toastr';
import { AuthService } from './auth.service';
//import {Gatekeeper} from 'gatekeeper-client-sdk';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    constructor(private router: Router, private toastr: ToastrService,private auth: AuthService) {}

    

     

    async getProfile() {
        try {
            console.log('get profile');
            this.user = this.auth.getUserInfo();// Gatekeeper.getProfile();

            this.user = this.auth.getCurrentUser;

            console.log(this.user);
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
         
        localStorage.removeItem('token');
        localStorage.removeItem('access_token');
        //localStorage.removeItem('gatekeeper_token');
        this.user = null;
        var randNumber = Math.random() * 10000;
        //this.router.navigate(['/login?r=' + randNumber]);
        this.router.navigateByUrl("login?r=" + randNumber);
    }
}
