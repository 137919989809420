<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    GreenhouseIoT
    <br>
    <hr>
    <div style="text-align: center!important">
        <img class="img-fluid"  height="50px;" src="/assets/img/logogh.png">
    </div>
</strong>
<span>
    <hr>
    <div style="text-align: center!important">
        Proiect cofinantat din Fondul European De Dezvoltare Regionala prin Programul Operațional Competitivitate 2014-2020
        <br>

        Pentru informatii detaliate despre celelalte programe cofinantate de Uniunea Europeana, va invitam sa vizitati <a href="www.fonduri-ue.ro">www.fonduri-ue.ro</a>
        <br>
        Conţinutul acestui material nu reprezintă în mod obligatoriu poziţia oficială a Uniunii Europene sau a Guvernului României.
    
    </div>
</span>
