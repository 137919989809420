import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { CrmapiService } from '@services/crmapi.service';
import { CasesapiService } from '@services/casesapi.service';
import { DeviceextapiService } from '@services/deviceextapi.service';
 

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit {

  public internalID = "";
  public devicesInfo : any[];

  constructor(private router: Router,private appService: AppService, private api : ApiService, private casesapi: CasesapiService,private crmapi : CrmapiService, private deviceextapi : DeviceextapiService) {}
  public profileData : any;
  ngOnInit(): void {

      let profileData = {};
      let crmProfile = {};
      

      

      this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
        this.profileData = profileData;
       
        //console.log(profileData["internalID"]);
        //JSON.parse(profileData)
        //this.user = data;
        this.internalID = profileData["internalID"];

        

        this.casesapi.getCases(this.internalID).subscribe((casesdata: any[])=>{
         
         
         
        var count = Object.keys(casesdata).length;

        this.devicesInfo = casesdata;//JSON.stringify(devicesdata);
        //$scope.devicesInfo = devicesdata;

        console.log(this.devicesInfo );
 
  
        //get devices info
  
  
        
      })


      })

      //end logic

       
       


  }

}
