<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1">
            <div style="text-align: center!important">
                <img width="250px;" src="/assets/img/logogh.png">
            </div>
        </a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Sign in to account</p>
        <div *ngIf="loginErrorMessage" class="alert alert-danger" role="alert">
            {{loginErrorMessage}}
          </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-12 d-flex justify-content-center mb-3">
  <ngx-recaptcha2 #captchaElem siteKey="6LfS9ogiAAAAANbBIofQ9R7BF4acIbLsgB_U44mD" (success)="handleSuccess($event)" [size]="size"
[hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha">
</ngx-recaptcha2>
                </div>
                <!-- /.col -->
                <div class="col-5">
                    <app-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                    >
                        Sign In
                    </app-button>
                </div>
                <!-- /.col -->
            </div>
        </form>

        

        <p class="mb-1">
            <a [routerLink]="['/forgot-password']">I forgot my password</a>
        </p>
        <!-- <p class="mb-0">
            <a [routerLink]="['/register']" class="text-center">
                Register a new membership
            </a>
        </p> -->
    </div>
    <!-- /.login-card-body -->
</div>
