import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { CrmapiService } from '@services/crmapi.service';
import { DocsService } from '@services/docs.service';
import { DeviceextapiService } from '@services/deviceextapi.service';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  public internalID = "";
  public devicesInfo : any[];

  constructor(private router: Router,private appService: AppService, private api : ApiService, private docs: DocsService,private crmapi : CrmapiService, private deviceextapi : DeviceextapiService) {}
  public profileData : any;

  ngOnInit(): void {

    let profileData = {};
    let crmProfile = {};
   // let devicesInfo = {};

    //let stringifiedData = {};

    this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
           
      this.profileData = profileData;
     
      //console.log(profileData["internalID"]);
      //JSON.parse(profileData)
      //this.user = data;
      this.internalID = profileData["internalID"];

      //this.internalID = "5ac40df4-37d9-acf3-35fb-6284df3e0658";

      this.docs.getDocs(this.internalID).subscribe((casesdata: any[])=>{
       //console.log("ua done"+this.internalID);
       
      //stringifiedData = JSON.stringify(devicesdata);
      var count = Object.keys(casesdata).length;

      this.devicesInfo = casesdata;//JSON.stringify(devicesdata);
      //$scope.devicesInfo = devicesdata;

      console.log(this.devicesInfo );


      //get devices info


      
    })


    })

    //end logic

     
     


}

  

}
