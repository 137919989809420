
import {
    Component,
    OnInit,
    Renderer2,
    OnDestroy,
    HostBinding
} from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import {ToastrService} from 'ngx-toastr';

import { ApiService } from '@services/api.service';
import { CasesapiService } from '@services/casesapi.service';

@Component({
  selector: 'app-caseform',
  templateUrl: './caseform.component.html',
  styleUrls: ['./caseform.component.scss']
})
export class CaseformComponent implements OnInit {

  public caseNewForm: UntypedFormGroup;

  public isAuthLoading = false;
    

    submitted = false;
    errRegisterMsg = "";

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private api : ApiService,
        private casesapi: CasesapiService,
        private authService: AuthService,
        private appService: AppService
    ) {}

  ngOnInit(): void {

    this.caseNewForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      description: new UntypedFormControl(null, Validators.required) 
  });

  }

  onSubmit() {
    console.log(this.caseNewForm.value);
 
    this.submitted = true;
        if (this.caseNewForm.valid) {
          //get the account id , after - post data for new case
          this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
            //this.profileData = profileData;
            //console.log("PD");
            //console.log(profileData["internalID"]);
            //JSON.parse(profileData)
            //this.user = data;
            const internalID = profileData["internalID"]; 
            console.log(internalID);
            this.casesapi.postCase(internalID,this.caseNewForm.value.name,this.caseNewForm.value.description).subscribe({
              next: data => {
                //this.router.navigateByUrl('/login');
                this.router.navigateByUrl('/cases');
              },
              error: err => {
                //this.errRegisterMsg = err.error.error;
                this.router.navigateByUrl('/cases');
              }
            });

          })
          this.router.navigateByUrl('/dashboard');
        }
        else{
          this.toastr.error('Please specify subject and description', 'Invalid form');
        }
  }

}
