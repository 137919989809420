import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { JwtService } from './jwt.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from './app.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlantcycleService {

  public apiURL : string="";
  public apikey : string="";
  constructor(private httpClient: HttpClient, private jwtService: JwtService, private appService: AppService) { 

   this.apiURL = environment.apiURL;
   

  }

  createAuthorizationHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
  }

  generalErrorHandler(error: any, caught: Observable<any>): Observable<any> {
    console.log('Call Error Caught: ', error.error);
    if( error.error.message == "INVALID_TOKEN" || error.error.message == "MAX_TOKEN_ISSUE_REACHED" || error.error.message == "Invalid authorization token"){
      console.log('Token has expired');
       
      return error;
    }
    return error;
  }

  getObjects(id: string): Observable<any> {
let headers = this.createAuthorizationHeader()
    console.log('gstpc');
    var resp = this.httpClient.get(this.apiURL + `/user/plantcycle/` + id, { headers: headers }).pipe(
        catchError( (err: any, caught: Observable<any>) => { return throwError(this.generalErrorHandler(err, caught)) } ) );
         
    return resp;
  }

  postObject(internalID:string,name:string,plantDay:string,plantTime:string,plantMassDry:string,plantMassWet:string,plantHarvestDay:string,plantHarvestTime:string,plantHarvestMass:string )
  {
      let body = {internalID:internalID,name:name,plantDay:plantDay,plantTime:plantTime,plantMassDry:plantMassDry,plantMassWet:plantMassWet,plantHarvestDay:plantHarvestDay,plantHarvestTime:plantHarvestTime,plantHarvestMass:plantHarvestMass };
     
      return this.httpClient.post(
        this.apiURL + `/user/plantcycleadd/`,
        body
      );
  }


}
