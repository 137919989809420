import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {AppService} from '@services/app.service';
import { ApiService } from '@services/api.service';
import { NotesapiService } from '@services/notesapi.service';

import {DateTime} from 'luxon';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;
    public internalID = "";
    public userEmail;

    public notesCount = 0;
    public bellVisible = false;

    public intHandle;

    constructor(private router: Router,private appService: AppService,private notesapi : NotesapiService,private api : ApiService) {}

    ngOnInit(): void {
        console.log('A')
        console.log(this.appService.user);
        console.log('A')
        this.user = this.appService.user;

        this.checkProfileAndNewNotes();

        this.intHandle = setInterval(() => {
            this.checkProfileAndNewNotes(); 
          }, 10000);
        //setTimeout(function(){
        //    console.log("waited for: " + i + " seconds");
        //    this.checkProfileAndNewNotes();
        //  }, 1000);
         

        
    }

    checkProfileAndNewNotes()
    {
         
        if(this.appService.user == null)
        {
            clearInterval(this.intHandle);
            return;
        }
         

        this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
            console.log(profileData);
            this.userEmail = profileData['email'];
            this.internalID = profileData["internalID"];

            this.notesapi.getNewNotes(this.internalID).subscribe((notesdata: any[])=>{
                console.log("ua new notes done"+notesdata);
                
               //stringifiedData = JSON.stringify(devicesdata);
               this.notesCount = Object.keys(notesdata).length;
               if (this.notesCount > 0)
                {
                    this.bellVisible = true;
                }
       
               
             })

        });
         
    }

    dismissNewNotes()
    {
    console.log('dismissNewNotes');
    this.notesapi.unsetNewNotes(this.internalID).subscribe((notesdata: any[])=>{
        console.log("unset new notes done"+notesdata);
        
       //stringifiedData = JSON.stringify(devicesdata);
       this.notesCount = Object.keys(notesdata).length;
       if (this.notesCount > 0)
        {
            this.bellVisible = true;
        }

       
     })

    }

    logout() {
        this.appService.logout();
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
