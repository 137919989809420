<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1">
            <div style="text-align: center!important">
                <img width="250px;" src="/assets/img/logogh.png">
            </div>
        </a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">
            You are only one step a way from loggin into. Please enter the OTP sent on your email.
        </p>
        <form [formGroup]="twofactorForm" (ngSubmit)="otpVerify()">
            <div class="input-group mb-3">
                <input
                    formControlName="otp"
                    type="text"
                    class="form-control"
                    placeholder="123456"
                    autocomplete="off"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-button [type]="'submit'" [block]="true">
                        Verify
                    </app-button>
                </div>
            </div>
        </form>
<!-- 
        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Login</a>
        </p> -->
    </div>
</div>
