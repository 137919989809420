import { HttpHeaders } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { CrmapiService } from '@services/crmapi.service';
import { NotesapiService } from '@services/notesapi.service';
import { DeviceextapiService } from '@services/deviceextapi.service';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})




export class ProfileComponent implements OnInit {
    public user;
    public crmProfile;
    public devicesInfo;

    public account_type = "";
    public crmAccountName = "";
    public crmCity = "";
    public crmUsername = "";

    //bill and ship info
    public billing_address_city = "";
    public billing_address_street = "";
    public billing_address_state = "";

    public shipping_address_state = "";
    public shipping_address_city = "";
    public shipping_address_street = "";
    //

    public internalID = "";

    public devicesCount = 0;
    public notesInfo : any[];

    constructor(private router: Router,private appService: AppService, private api : ApiService,private notesapi:NotesapiService, private crmapi : CrmapiService, private deviceextapi : DeviceextapiService) {}
    public profileData : any;
    ngOnInit(): void {

      
        let profileData = {};
        let crmProfile = {};
        let devicesInfo = {};

        //correct
        this.user = this.appService.user;

       // console.log("USER:");
       // console.log(this.user);

        this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
            this.profileData = profileData;
            //console.log("PD");
            //console.log(profileData["internalID"]);
            //JSON.parse(profileData)
            //this.user = data;
            this.internalID = profileData["internalID"];//profileData.internalID;
            console.log(this.internalID);

            //get profile data

            this.crmapi.checkUserAccountOnline(this.internalID).subscribe((crmdata: any[])=>{
                //console.log("ua done");
              //console.log(crmdata);
              this.crmProfile = crmdata[0];
  
              console.log(this.crmProfile);
              this.account_type = this.crmProfile.account_type;
              this.crmAccountName = this.crmProfile.name;
              this.crmCity = this.crmProfile.city;
              this.crmUsername = this.crmProfile.username;

              //bill
              this.billing_address_city = this.crmProfile.billing_address_city;
              this.billing_address_street = this.crmProfile.billing_address_street;
              this.billing_address_state = this.crmProfile.billing_address_state;

              this.shipping_address_state = this.crmProfile.shipping_address_state;
              this.shipping_address_city = this.crmProfile.shipping_address_city;
              this.shipping_address_street = this.crmProfile.shipping_address_street;

              //console.log("use account done. getting devices...");
              //get devices info
              this.deviceextapi.checkUserAccountDevices(this.internalID).subscribe((devicesdata: any[])=>{
                console.log("ua done");
              console.log(devicesdata);

              this.devicesCount = Object.keys(devicesdata).length;
              
              //this.crmProfile = crmdata[0];
  
             

              //get devices info


              
            })

          
              //end get dev info

               //get notes
               this.notesapi.getNotes(this.internalID).subscribe((notesdata: any[])=>{
                console.log("user notes [crm] done");
                console.log(notesdata);
                this.notesInfo = notesdata;

              })
              //end get notes

            

            }) 

          })  
             

          console.log(this.crmProfile);

        
    }

    logout() {
        this.appService.logout();
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
