import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { CrmapiService } from '@services/crmapi.service';
import { InvoicesapiService } from '@services/invoicesapi.service';
import { DeviceextapiService } from '@services/deviceextapi.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  public internalID = "";
  public pdfContent = "";
 public pdfencoded: any;
 @ViewChild('pdfview') pdfview: ElementRef;
   

  constructor(private router: Router,private route: ActivatedRoute,private appService: AppService, private api : ApiService, private invoicesapi: InvoicesapiService,private crmapi : CrmapiService, private deviceextapi : DeviceextapiService) {}
  public profileData : any;

  b64toBlob(b64Data, contentType) {
    var byteCharacters = atob(b64Data);

    var byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  ngOnInit(): void {

    

    let profileData = {};
    let crmProfile = {};
   // let devicesInfo = {};

    //let stringifiedData = {};

    const allParams = this.route.snapshot.params;
    const id = allParams.id; 

    this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
           
      this.profileData = profileData;
     
      //console.log(profileData["internalID"]);
      //JSON.parse(profileData)
      //this.user = data;
      this.internalID = profileData["internalID"];

      //this.internalID = "5ac40df4-37d9-acf3-35fb-6284df3e0658";

      this.invoicesapi.getInvoice(id).subscribe((casesdata: any[])=>{
       //console.log("ua done"+this.internalID);
       
      //stringifiedData = JSON.stringify(devicesdata);
      //var count = Object.keys(casesdata).length;

      //this.devicesInfo = casesdata;//JSON.stringify(devicesdata);
      //$scope.devicesInfo = devicesdata;

      this.pdfencoded = casesdata['content'] ;

      //console.log(this.b64toBlob(this.pdfencoded, 'application/pdf'));

      //this.pdfContent = window.URL.createObjectURL(this.b64toBlob(this.pdfencoded, 'application/pdf')) ;//+
      //'#toolbar=0&navpanes=0&scrollbar=0&view=FitH';
      
      //this.pdfview.nativeElement.setAttribute('data', this.b64toBlob(this.pdfencoded,'application/pdf'));
      this.pdfview.nativeElement.setAttribute('data','data:application/pdf;base64,'+this.pdfencoded );
      //console.log(this.pdfContent);
      //let url = window.URL.createObjectURL(this.pdfencoded,);
      //get info
      //this.pdfContent=this.sanitizer.bypassSecurityTrustResourceUrl(url);

      
    })


    })

    //end logic

  }

}
