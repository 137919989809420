<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>{{devicesCount}}</h3>

                        <p>Devices</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-briefcase"></i>
                    </div>
                    <a routerLink="/devices" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>{{account_type}}</h3>
                        <p>{{crmAccountName}}</p>
                       
                    </div>
                    <div class="icon">
                        <i class="ion ion-at"></i>
                    </div>
                    <a routerLink="/profile" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{casesCount}}</h3>

                        <p>Account cases</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-card"></i>
                    </div>
                    <a routerLink="/cases" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>{{notesCount}}</h3>

                        <p>Account Notes</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-information-circled"></i>
                    </div>
                    <a routerLink="/notes" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></a>
                </div>
            </div>
            <!-- ./col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->
