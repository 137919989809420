import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';
import { ApiService } from '@services/api.service';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;

    public userEmail;

    constructor(
        public appService: AppService,
        private api : ApiService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
            console.log(profileData);
            this.userEmail = profileData['email'];

        });
        
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        path: ['/']
    },
    {
        name: 'InfoCenter',
        path: ['/notes']
    },
    {
        name: 'Devices',
        path: ['/devices']
    },
    {
        name: 'Cases',
        children: [
            {
                name: 'Cases List',
                path: ['/cases']
            },

            {
                name: 'Add case',
                path: ['/caseform']
            }
        ]
    },
    {
        name: 'Invoices',
        path: ['/invoices']
    }
    ,
    {
        name: 'Documents',
        path: ['/docs']
    }
    ,
    {
        name: 'Watering',
        children: [
            {
                name: 'Water Dosage',
                path: ['/waterdosage']
            },

            {
                name: 'Add new dosage item',
                path: ['/waterdosageadd']
            }
        ]
    },
    {
        name: 'Planting',
        children: [
            {
                name: 'Plant Cycles',
                path: ['/plantcycle']
            },

            {
                name: 'Add new plant cycle',
                path: ['/plantcycleadd']
            }
        ]
    },
    {
        name: 'EcoCenter',
        children: [
            {
                name: 'Costs',
                path: ['/cost']
            },

            {
                name: 'Add new cost',
                path: ['/costadd']
            },
            {
                name: 'Surface Economy Calculator',
                path: ['/surface-economy']
            },
            {
                name: 'Water Economy Calculator',
                path: ['/water-economy']
            },
            {
                name: 'CO2 Economy Calculator',
                path: ['/co2-economy']
            }

        ]
    },
    {
        name: 'Triggers',
        children: [
            {
                name: 'View',
                path: ['/usertrigger']
            },

            {
                name: 'Set trigger',
                path: ['/usertriggeradd']
            }
        ]
    }
];