import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apiURL = environment.apiURL;
  constructor() { }
  access_token = localStorage.getItem('access_token')

  makePayment(order) {
    console.log("ordering")
    var data = {
      name: order.name,
      price: order.total_amount,
      id: order.id,
      account_id: order.account_id
    }

    var config = {
      method: 'post',
      url: this.apiURL + `/user/invoices/make-payment`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.access_token
      },
      data: data
    };

    axios(config)
      .then((response2: any) => {

        window.open(response2.data.url, '_self');

      })
      .catch((error) => {
        console.log(error);
      });




  }
}
