import { HttpHeaders } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { CrmapiService } from '@services/crmapi.service';
import { NotesapiService } from '@services/notesapi.service';
import { CasesapiService } from '@services/casesapi.service';
import { DeviceextapiService } from '@services/deviceextapi.service';
import {DateTime} from 'luxon';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    
    public notesInfo : any[];
    public casesInfo : any[];

    public user;
    public crmProfile;
    public devicesInfo;

    public account_type = "";
    public crmAccountName = "";
    public crmCity = "";
    public crmUsername = "";

    public internalID = "";

    public devicesCount = 0;
    public notesCount = 0;
    public casesCount = 0;

    constructor(private router: Router,private appService: AppService, private api : ApiService, private crmapi : CrmapiService,private casesapi : CasesapiService,private notesapi : NotesapiService, private deviceextapi : DeviceextapiService) {}
    public profileData : any;
    ngOnInit(): void {

        let profileData = {};
        let crmProfile = {};
        let devicesInfo = {};
        let notes = {};

        //correct
        this.user = this.appService.user;

       // console.log("USER:");
       // console.log(this.user);

        this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
            this.profileData = profileData;
            //console.log("PD");
            //console.log(profileData["internalID"]);
            //JSON.parse(profileData)
            //this.user = data;
            this.internalID = profileData["internalID"];//profileData.internalID;
            console.log(this.internalID);

            //get profile data

            this.crmapi.checkUserAccountOnline(this.internalID).subscribe((crmdata: any[])=>{
                //console.log("ua done");
              //console.log(crmdata);
              this.crmProfile = crmdata[0];
  
              console.log(this.crmProfile);
              this.account_type = this.crmProfile.account_type;
              this.crmAccountName = this.crmProfile.name;
              this.crmCity = this.crmProfile.city;
              this.crmUsername = this.crmProfile.username;

              //console.log("use account done. getting devices...");
              //get devices info
              this.deviceextapi.checkUserAccountDevices(this.internalID).subscribe((devicesdata: any[])=>{
               console.log("user devices done");
               console.log(devicesdata);

              this.devicesCount = Object.keys(devicesdata).length;
              
              //this.crmProfile = crmdata[0];
  
             

              //get devices info

              //get notes
              this.notesapi.getNotes(this.internalID).subscribe((notesdata: any[])=>{
                console.log("user notes [crm] done");
                //console.log(notesdata);
                this.notesCount = Object.keys(notesdata).length;

                this.notesInfo = notesdata;

              })
              //end get notes
              this.casesapi.getCases(this.internalID).subscribe((casesdata: any[])=>{
                console.log("user cases [crm] done");
                //console.log(notesdata);
                this.casesCount = Object.keys(casesdata).length;

                this.casesInfo = casesdata;

              })
              //get cases

              //end get cases


              
            })

          
              //end get dev info

            

            }) 

          })  
             

          console.log(this.crmProfile);

        
    }

    logout() {
        this.appService.logout();
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }

}
