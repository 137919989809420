import { Component, OnInit } from '@angular/core';

import { JsonpClientBackend } from '@angular/common/http';

import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { CostService } from '@services/cost.service';

import { CsvDataService } from '@services/csv-data-service.service';
@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss']
})
export class CostComponent implements OnInit {

  public internalID = "";
  public objectsInfo : any[];

  constructor(private router: Router,private csvService :CsvDataService,private appService: AppService, private api : ApiService, private costservice: CostService) { }
  public profileData : any;

    ngOnInit(): void {

    let profileData = {};
      

      this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
        this.profileData = profileData;
               
        this.internalID = profileData["internalID"];
        

        this.costservice.getObjects(this.internalID).subscribe((objData: any[])=>{
                 
         
        //var count = Object.keys(objData).length;

        this.objectsInfo = objData;//JSON.stringify(objData);
        

        console.log(this.objectsInfo );
 
  
        //get info
  
  
        
      })


      })

      const btn: HTMLElement = document.getElementById('export');
      btn.addEventListener('click', () => {
        CsvDataService.exportToCsv('export.csv', this.objectsInfo);
      });
      //end logic
      

  }

}