<div class="container d-flex h-100">
    <div role="form" class="main-form border rounded shadow-sm bg-white">
      <div class="row">
        <div class="col text-center">
          <h5  class="success-alert" role="alert">
            Account successfully created.
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <div class="mx-auto">
            <p class="ml-2 mr-2">You have received an email with instructions in 
                order to complete the registration process. The account must be confirmed in order
                to login to your account</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="submit" routerLink="/login" class="btn btn-primary login-btn-color">Login</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <br> 
        </div>
      </div>
    </div>
  </div>
  