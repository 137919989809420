import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Pipe({
  name: 'htmlpipe'
})
export class HtmlpipePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  transform(value: any): unknown {
    //return value + "oki";
    let val
    if (value) {
      val = value.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    }

    console.log(val);

    return val; // this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
