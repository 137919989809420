import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { JwtService } from './jwt.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from './app.service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public apiURL : string="";
    constructor(private httpClient: HttpClient, private jwtService: JwtService, private appService: AppService) {
         this.apiURL = environment.apiURL;
    }
  
  createAuthorizationHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
  }
     

      generalErrorHandler(error: any, caught: Observable<any>): Observable<any> {
        console.log('Error Caught: ', error.error);
        if( error.error.message == "INVALID_TOKEN" || error.error.message == "MAX_TOKEN_ISSUE_REACHED" || error.error.message == "Invalid authorization token"){
          console.log('Token has expired');
  
          this.appService.logout();
          return error;
        }
        return error;
      }

  getUserProfileAndInfo(): Observable<any> {
    let headers = this.createAuthorizationHeader()
    
    console.log('getUserProfileAndInfo');
    var resp = this.httpClient.get(this.apiURL + `/user/profile`, { headers: headers }).pipe(
            catchError( (err: any, caught: Observable<any>) => { return throwError(this.generalErrorHandler(err, caught)) } ) );
             
        return resp;
      }
      
}
