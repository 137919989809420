<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Watering</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Water dosages</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Water dosages specifications</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-sm-4 ">
                        <form [formGroup]="waterDosageForm">
                            <div>
                                <label> Select zone</label>
                                <div class="text-center">
                                    <i *ngIf="zones.length==0" class="fas
                                        fa-spinner fa-spin fa-2x"></i>
                                </div>
                                <div class="input-group mb-3"
                                    *ngIf="zones.length>0">
                                    <select
                                        formControlName="zoneID"
                                        type="s"
                                        class="datepicker"
                                        class="form-control"
                                        placeholder="Start date">
                                        <option *ngFor="let zone of zones; let i=
                                            index" [ngValue]="zone.id"
                                            >{{i+1}}. {{zone.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <label> Start Date</label>
                            <div class="input-group mb-3">
                                <input
                                [readOnly]="wateringStages.length>0"
                                formControlName="startDate"
                                type="date"
                                class="datepicker"
                                class="form-control"
                                placeholder="Start date"
                                />
                            </div>
                            <label> Start Hour</label>
                            <div class="input-group mb-3">
                                <input
                                    [readOnly]="wateringStages.length>0"
                                formControlName="startHour"
                                type="time"
                                pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                                class="form-control"
                                placeholder="Time [hh:mm]"
                                />
                            </div>
                            <label>Ammount of days to run:</label>
                            <div class="input-group mb-3">
                                <input (keypress)="keyPressNumbers($event)"
                                    formControlName="daysAmmount"
                                    type="number"
                                    class="form-control"
                                    placeholder="Ammount of days to run this schedule"
                                    inputmode="numeric" pattern="[0-9]*"
                                    />
                            </div>

                            <label>Ammount runs per day:</label>
                            <div class="input-group mb-3">
                                <input (keypress)="keyPressNumbers($event)"
                                    formControlName="dailyRuns"
                                    type="number"
                                    class="form-control"
                                    placeholder="How many times a day should watering occur"
                                    inputmode="numeric" pattern="[0-9]*"
                                    />
                            </div>

                            <label>Watering duration:</label>
                            <div class="input-group mb-3">
                                <input (keypress)="keyPressNumbers($event)"
                                    formControlName="dosageDuration"
                                    type="number"
                                    pattern="[0-9]+"
                                    class="form-control"
                                    placeholder="Duration [min]"
                                    inputmode="numeric" pattern="[0-9]*"

                                    />
                            </div>


                            <div class="row ">

                                <!-- /.col -->
                                <div class="col text-center">
                                    <button (click)="requestAddWateringStep()"
                                        class="btn btn-primary btn-block">
                                        Add stage
                                    </button>
                                </div>
                                <div class="col text-center">
                                    <button (click)="onSubmit()" class="btn
                                        btn-success btn-block">
                                        Save and schedule
                                    </button>
                                </div>
                                <!-- /.col -->
                            </div>
                        </form>
                    </div>

                    <div class="col-sm-8">
                        <label>Water dosage stages</label>
                        <table class="table table-responsive w-100">
                            <tr class="w-100">
                                <th>Start Date</th>
                                <th>Start Hour</th>
                                <th>Days to run</th>
                                <th>Runs/day</th>
                                <th>Watering duration</th>




                            </tr>
                            <tr *ngFor="let stage of wateringStages; index as
                                i">
                                <td>{{stage.startDate}}</td>
                                <td>{{stage.startHour}}</td>
                                <td>{{stage.daysAmmount}}</td>
                                <td>{{stage.dailyRuns}}</td>
                                <td>{{stage.dosageDuration}}</td>
                                <td *ngIf="wateringStages.length-1==i"><button
                                        (click)="deleteWateringStep(stage.startDate)"
                                        class="btn btn-danger">Delete </button></td>
                            </tr>
                        </table>

                    </div>
                </div>

            </div>
            <!-- /.card-body -->
            <div class="card-footer"></div>
            <!-- /.card-footer-->
        </div>
    </div>

    <!-- /.card -->
</section>
<!-- /.content -->
