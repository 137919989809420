import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding,
    ElementRef,
    ViewChild
} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { TfaService } from '@services/tfa.service';
import { JwtService } from '@services/jwt.service';
import { ReCaptcha2Component } from 'ngx-captcha';
//import { NgForm } from "@angular/forms";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public loginForm: UntypedFormGroup;
    private isBot: Boolean = true
    
    userModel = {
        email: "",
        password: ""
      };
    loginErrorMessage: string;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;


    @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
    @ViewChild('langInput') langInput: ElementRef;

    public captchaIsLoaded = false;
    public captchaSuccess = false;
    public captchaIsExpired = false;
    public captchaResponse?: string;

    public theme: 'light' | 'dark' = 'dark';
    public size: 'compact' | 'normal' = 'normal';
    public lang = 'en';
    public type: 'image' | 'audio';

    constructor(
        private auth: AuthService,
        private router: Router,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private tfaService: TfaService,
        private jwtService: JwtService
    ) {}

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page-ex'
        );
        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl(null, Validators.required),
            password: new UntypedFormControl(null, Validators.required),
            recaptcha: new UntypedFormControl(null, [Validators.required]),
        });
    }

    onSubmit() {
        this.loginForm.markAllAsTouched();
        console.log(!this.isBot, this.loginForm.controls['email'].valid, this.loginForm.controls['password'].valid)
        if (!this.isBot && this.loginForm.controls['email'].valid && this.loginForm.controls['password'].valid) {
            this.jwtService.deleteToken();
            this.auth.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value).subscribe({
                next: (data: any) => {
                    this.captchaElem.resetCaptcha();
                    this.isBot=true
                    if (data.initialized == true) {
                        if (
                            this.auth.getTwoFactorType() === "app" ||
                            this.auth.getTwoFactorType() === "email"
                        ) {
                            this.auth.tempUser = data
                            this.tfaService.generateTotp(data.token).subscribe();
                            this.router.navigateByUrl("/twofactor");
                        } else {
                            this.router.navigateByUrl("/dashboard");
                        }

                    }
                    else {
                        this.auth.tempUser = data
                        this.router.navigateByUrl("/init");

                    }
                },
                error: err => {
                    this.captchaElem.resetCaptcha();
                    this.isBot = true
                    this.auth.purgeAuth()
                    this.loginErrorMessage = err.error.error
                }
            });
        }
       
      }
     
/*
    async loginByAuth() {
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            await this.appService.loginByAuth(this.loginForm.value);
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Form is not valid!');
        }   
    }
    */

   

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page-ex'
        );
    }


    handleSuccess(data) {
        console.log(data)
        this.isBot=false
    }
}
