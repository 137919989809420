import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';

@Injectable({
  providedIn: "root"
})
export class TfaService {
  public apiURL : string="";
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.apiURL = environment.apiURL;
  }

  enableAppTwoFactor(): Observable<any> {
    return this.httpClient
      .get(this.apiURL+"/user/generate-secret")
      .pipe(
        map((res: any) => {
          this.updateTfaStatus("app");
          return res;
        })
      );
  }

  generateEmailSecret(): Observable<any> {
    return this.httpClient
      .get(this.apiURL+"/user/generate-secret-email")
      .pipe(
        map((res: any) => {
          this.updateTfaStatus("email");
          return res;
        })
      );
  }

  createAuthorizationHeader(access_token) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + access_token
    });
  }

  generateTotp(token:any): Observable<any> {
    let headers = this.createAuthorizationHeader(token)
    console.log(headers)
    return this.httpClient.get(this.apiURL+"/user/generate-totp",{headers:headers}).pipe(
      map((res: any) => {
          console.log(res)
          this.authService.otp=res.otp
          return res;
        })
      );;
  }

  verifyTotp(totp: any, token: any): Observable<any> {
    let headers = this.createAuthorizationHeader(token)
    console.log(headers)
    return this.httpClient.post(
      this.apiURL+"/user/verify-totp",
      { otp: totp }, { headers: headers }
    );
  }

  private updateTfaStatus(tfaType: string) {
    console.log("Setting new 2FA status to", tfaType);
    const user = this.authService.getCurrentUser;
    user.twoFactorType = tfaType;
    this.authService.setUser(user);
  }
}
