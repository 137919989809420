import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../models/User.model';
import { JwtService } from './jwt.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private authenticated: boolean;
  private firstLogin: boolean
  public apiURL : string="";
  public tempUser: User
  public otp: any
  constructor(private httpClient: HttpClient, private jwtService: JwtService, private router: Router,) {
    this.currentUserSubject = new BehaviorSubject<User>({} as User);
    this.currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
    this.authenticated = false;
    this.apiURL = environment.apiURL;
  }

  login(email: any, password: any): Observable<User> {
    return this.httpClient.post(
      this.apiURL + '/user/login',
      {
        email: email,
        password: password

      }
      
    ).pipe(map(
      (res: any) => {
        console.log(res.initialized)
        if (res.initialized==true) {
          this.setAuth(res);
          return res;
        }
       
        
        return res
      }
    ));
  }

  register(user: User): Observable<any> {
    return this.httpClient.post(this.apiURL+'/user/register', user);
  }

  getUserInfo(): Observable<any> {
    return this.httpClient.get(this.apiURL+`/user/profile`);
  }

  getUserPayload() {
    if (this.jwtService.getToken()) {
      this.getUserInfo().subscribe({
        next: (res: any) => {
          this.setAuth(res);
        },
        error: (err) => {
          this.purgeAuth();
        }
      });
    } else {
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    console.log(user)
    this.jwtService.setToken(user.token);
    this.setUser(user);
    this.authenticated = true;
  }

  setUser(user: User) {
    //console.log('Set user', user);
    this.currentUserSubject.next(user);
  }

  getTwoFactorType() {
    console.log(this.currentUserSubject.value.twoFactorEnabled)
    return this.currentUserSubject.value.twoFactorType;
  }

  getIfIsInitialized() {
    return this.currentUserSubject.value.initialized;
  }

  purgeAuth() {
    console.log('Session has been purged');
    this.jwtService.deleteToken();
    this.currentUserSubject.next({} as User);
    this.authenticated = false;
  }

  public isAuthenticated(): boolean {
    return this.authenticated;
  }

  public get getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  getCurrentUserObs(): Observable<User> {
    return this.currentUser;
  }

  requestReset(body: any): Observable<any> {
    return this.httpClient.post(
      this.apiURL+`/user/resetLogin`,
      body
    );
  }

  newPassword(token: string, body: any): Observable<any> {
    return this.httpClient.post(
      this.apiURL+`/user/changePassword/${token}`,
      body
    );
  }

  validPasswordToken(token: string): Observable<any> {
    return this.httpClient.get(
      this.apiURL+`/user/resetPasswordConfirmation/${token}`
    );
  }

  confirmEmail(token: string): Observable<any> {
    return this.httpClient.get(
      this.apiURL+`/user/confirmation/${token}`,
      { observe: 'response' }
    );
  }
}
