import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { JwtService } from './jwt.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from './app.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WaterdosageService {


  public apiURL: string = "";
  public apikey: string = "";
  constructor(private httpClient: HttpClient, private jwtService: JwtService, private appService: AppService) {

    this.apiURL = environment.apiURL;


  }

  createAuthorizationHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
  }

  generalErrorHandler(error: any, caught: Observable<any>): Observable<any> {
    console.log('Call Error Caught: ', error.error);
    if (error.error.message == "INVALID_TOKEN" || error.error.message == "MAX_TOKEN_ISSUE_REACHED" || error.error.message == "Invalid authorization token") {
      console.log('Token has expired');

      return error;
    }
    return error;
  }

  getObjects(id: string): Observable<any> {
    let headers = this.createAuthorizationHeader()

    console.log('getwaterdosage');
    var resp = this.httpClient.get(this.apiURL + `/user/waterdosage/` + id, { headers: headers }).pipe(
      catchError((err: any, caught: Observable<any>) => { return throwError(this.generalErrorHandler(err, caught)) }));

    return resp;
  }

  postObject(internalID: string, stages: any, zoneID) {
    let headers = this.createAuthorizationHeader()
    let body = { internalID: internalID, stages: stages, zoneID: zoneID };
    return this.httpClient.post(
      this.apiURL + `/user/waterdosageadd/`,
      body,
      { headers: headers }
    );
  }

  postApiKey(apiKey, type) {
    let headers = this.createAuthorizationHeader()

    let body = { apiKey: apiKey, type: type };

    return this.httpClient.post(
      this.apiURL + `/user/waterdosage-apiKey`,
      body,
      { headers: headers }
    );
  }

  getZones() {
    let headers = this.createAuthorizationHeader()

    let body = {};

    return this.httpClient.post(
      this.apiURL + `/user/waterdosage-getZones`,
      body,
      { headers: headers }
    );
  }


  deleteApiKey(type) {
    let headers = this.createAuthorizationHeader()

    let body = { type: type };

    return this.httpClient.post(
      this.apiURL + `/user/waterdosage-apiKey-delete`,
      body,
      { headers: headers }
    );
  }

}
