
<div class="d-flex justify-content-between w-100" >
    <div class="d-flex justify-content-center">
        <a class="nav-link" (click)="onToggleMenuSidebar()" role="button"
            ><i class="fas fa-bars"></i
        ></a>
    </div>


    
    <div class="d-flex justify-content-center">
        <img  style="width:100%;max-height: 100px!important;" src="/assets/img/headerue.jpg">
    </div>
  


    <div class="d-flex justify-content-center">
        <ul class="navbar-nav ">
            <!--<app-messages></app-messages>-->
            <!--<app-notifications></app-notifications>-->
            <!--<app-language></app-language>-->
            <app-user></app-user>
            <!--<li class="nav-item">
                <button class="nav-link" (click)="onToggleControlSidebar()">
                    <i class="fas fa-th-large"></i>
                </button>
            </li>-->
        </ul>
    </div>
</div>