<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Eco Center</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Surface Economy</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Surface economy calculator</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row w-100">
                    <form [formGroup]="surfaceEconomyForm" class="w-100">
                        <label>Monthly cultivated surface in square meters (m²) with our solution:</label>
                        <div class="input-group mb-3">
                            <input (keypress)="keyPressNumbers($event)"
                                formControlName="surface"
                                type="number"
                                class="form-control"
                                placeholder="Enter the monthly cultivated surface in square meters (m²)"
                                (keyup)="calculate()"
                                (change)="calculate()"
                                 inputmode="numeric" pattern="[0-9]*"
                                />
                        </div>

                    </form>
                </div>

                <div class="row">
                    <div class="page-header" *ngIf="economy">
                        <h4 >Surface economy: <span style="color:red">{{this.economy}}</span></h4> 
                    </div>
                </div>




            </div>
            <!-- /.card-body -->
            <div class="card-footer"></div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
