
import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostBinding
} from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from '@services/api.service';
import { WaterdosageService } from '@services/waterdosage.service';
import { formatDate } from '@angular/common'


@Component({
  selector: 'app-waterdosageadd',
  templateUrl: './waterdosageadd.component.html',
  styleUrls: ['./waterdosageadd.component.scss']
})
export class WaterdosageaddComponent implements OnInit {

  public waterDosageForm: FormGroup;

  public isAuthLoading = false;

  zones = []

  wateringStages = []
  submitted = false;
  errRegisterMsg = "";

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private api: ApiService,
    private waterdosage: WaterdosageService,
    private authService: AuthService,
    private appService: AppService
  ) { }

  ngOnInit(): void {

    this.api.getUserProfileAndInfo().subscribe((profileData: any) => {

      if (!profileData.rachioApiKey) {
        this.toastr.error('You dont have connected any Rachi Api key!', 'Error');
        this.router.navigateByUrl('/waterdosage')
      }

    })


    this.waterdosage.getZones().subscribe((zones: any) => {

      this.zones = zones.data
      console.log(this.zones)

    })


    this.waterDosageForm = new FormGroup({
      startDate: new FormControl(null, Validators.required),
      startHour: new FormControl(null, Validators.required),
      daysAmmount: new FormControl(null, Validators.required),
      dailyRuns: new FormControl(null, Validators.required),
      dosageDuration: new FormControl(null, Validators.required),
      zoneID: new FormControl(null, Validators.required),

    });

    this.setDefaultDateTime()
    // this.setdefaultStages()

  }

  ngOnDestroy() {
    this.wateringStages = []
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.waterDosageForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid)
    return invalid;
  }

  onSubmit() {
    console.log(this.waterDosageForm.value);


    this.submitted = true;
    if (this.wateringStages.length > 0 && this.waterDosageForm.value.zoneID) {
      //get the account id , after - post data for new case
      this.api.getUserProfileAndInfo().subscribe((profileData: any[]) => {

        //this.profileData = profileData;
        console.log("PD");
        //console.log(profileData["internalID"]);
        //JSON.parse(profileData)
        //this.user = data;
        const internalID = profileData["internalID"];
        console.log(internalID);
        console.log(this.wateringStages)
        this.waterdosage.postObject(internalID, this.wateringStages, this.waterDosageForm.value.zoneID).subscribe({
          next: data => {
            //this.router.navigateByUrl('/login');
            this.router.navigateByUrl('/waterdosage');
          },
          error: err => {
            this.toastr.error(err.error.error, 'Error');
            //this.errRegisterMsg = err.error.error;
            // this.router.navigateByUrl('/waterdosage');
          }
        });


      })
    }
    else {
      this.toastr.error('Please specify required data', 'Invalid form');
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  requestAddWateringStep() {
    this.waterDosageForm.markAllAsTouched()
    console.log(this.waterDosageForm.value.startDate)
    this.findInvalidControls()
    if (this.waterDosageForm.valid) {
      this.addWateringStep(
        this.waterDosageForm.value.startDate,
        this.waterDosageForm.value.startHour,
        this.waterDosageForm.value.daysAmmount,
        this.waterDosageForm.value.dailyRuns,
        this.waterDosageForm.value.dosageDuration,
      )
      this.waterDosageForm.markAsUntouched()
    }
  }

  addWateringStep(startDate, startHour, daysAmmount, dailyRuns, dosageDuration) {
    this.wateringStages.push({
      startDate: startDate,
      startHour: startHour,
      daysAmmount: daysAmmount,
      dailyRuns: dailyRuns,
      dosageDuration: dosageDuration
    })
    this.setNextStageDate();
    this.clearForm()
    console.log(this.wateringStages)


  }

  deleteWateringStep(startDate) {
    this.wateringStages = this.wateringStages.filter(item => item.startDate !== startDate)
  }

  setNextStageDate() {
    var date = new Date(this.waterDosageForm.value.startDate);
    var nextDate = new Date();
    this.waterDosageForm.controls.startDate.setValue(formatDate(nextDate.setDate(date.getDate() + this.waterDosageForm.value.daysAmmount), 'yyyy-MM-dd', 'en'));
    return nextDate
  }

  setDefaultDateTime() {
    var today = new Date();
    this.waterDosageForm.controls.startDate.setValue(formatDate(today, 'yyyy-MM-dd', 'en'));
    this.waterDosageForm.controls.startHour.setValue(new Date().getHours() + ':' + ('0' + new Date().getMinutes()).slice(-2))
    console.log(new Date().getHours() + ':' + new Date().getMinutes())

  }

  clearForm() {
    this.waterDosageForm.controls.daysAmmount.reset()
    this.waterDosageForm.controls.dailyRuns.reset()
    this.waterDosageForm.controls.dosageDuration.reset()
  }

  setdefaultStages() {
    this.wateringStages = [
      {
        "startDate": "2022-11-02",
        "startHour": "16:39",
        "daysAmmount": 3,
        "dailyRuns": 4,
        "dosageDuration": 4
      },
      {
        "startDate": "2022-11-05",
        "startHour": "16:39",
        "daysAmmount": 4,
        "dailyRuns": 6,
        "dosageDuration": 4
      }
    ]
  }


}
