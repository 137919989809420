import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-water-economy-calculator',
  templateUrl: './water-economy-calculator.component.html',
  styleUrls: ['./water-economy-calculator.component.scss']
})
export class WaterEconomyCalculatorComponent implements OnInit {

  economy: any
  new_consumption: any

  waterEconomyForm = new FormGroup({
    waterConsumption: new FormControl('', [Validators.required]),

  });

  constructor() { }

  ngOnInit(): void {
  }



  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  calculate() {
    let economy = (92 / 100 * parseFloat(this.waterEconomyForm.controls.waterConsumption.value)).toFixed(2)
    this.economy = `${economy} m³`
  }

}
