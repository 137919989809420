import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-registered',
  templateUrl: './user-registered.component.html',
  styleUrls: ['./user-registered.component.scss']
})
export class UserRegisteredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
