import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import { NgxCaptchaModule } from 'ngx-captcha';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';

import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {ButtonComponent} from './components/button/button.component';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {DropdownMenuComponent} from './components/dropdown/dropdown-menu/dropdown-menu.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from './components/select/select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AuthService } from '@services/auth.service';
import { ConfirmEmailComponent } from './modules/confirm-email/confirm-email.component';
import { UserRegisteredComponent } from './modules/user-registered/user-registered.component';
import { ResponseResetComponent } from './modules/response-reset/response-reset.component';
import { DevicesComponent } from './pages/devices/devices/devices.component';
import { DeviceComponent } from './pages/device/device/device.component';
import { NotesComponent } from './pages/notes/notes.component';
import { CasesComponent } from './pages/cases/cases.component';
import { CaseformComponent } from './pages/caseform/caseform.component';
import { HtmlpipePipe } from './htmlpipe.pipe';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { WaterdosageComponent } from './pages/waterdosage/waterdosage.component';
import { WaterdosageaddComponent } from './pages/waterdosageadd/waterdosageadd.component';
import { PlantcycleComponent } from './pages/plantcycle/plantcycle.component';
import { PlantcycleaddComponent } from './pages/plantcycleadd/plantcycleadd.component';
import { CostaddComponent } from './pages/costadd/costadd.component';
import { CostComponent } from './pages/cost/cost.component';
import { DocsComponent } from './pages/docs/docs.component';
import { DocComponent } from './pages/doc/doc.component';
import { NewnotesComponent } from './pages/newnotes/newnotes.component';
import { UsertriggerComponent } from './pages/usertrigger/usertrigger.component';
import { UsertriggeraddComponent } from './pages/usertriggeradd/usertriggeradd.component';
import { TwofactorComponent } from './modules/twofactor/twofactor.component';
import { WaterEconomyCalculatorComponent } from './pages/water-economy-calculator/water-economy-calculator.component';
import { Co2EconomyCalculatorComponent } from './pages/co2-economy-calculator/co2-economy-calculator.component';
import { SurfaceEconomyCalculatorComponent } from './pages/surface-economy-calculator/surface-economy-calculator.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        ButtonComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        PrivacyPolicyComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        DropdownComponent,
        DropdownMenuComponent,
        ControlSidebarComponent,
        SelectComponent,
        CheckboxComponent,
        ConfirmEmailComponent,
        UserRegisteredComponent,
        ResponseResetComponent,
        DevicesComponent,
        DeviceComponent,
        NotesComponent,
        CasesComponent,
        CaseformComponent,
        HtmlpipePipe,
        InvoicesComponent,
        InvoiceComponent,
        WaterdosageComponent,
        WaterdosageaddComponent,
        PlantcycleComponent,
        PlantcycleaddComponent,
        CostaddComponent,
        CostComponent,
        DocsComponent,
        DocComponent,
        NewnotesComponent,
        UsertriggerComponent,
        UsertriggeraddComponent,
        TwofactorComponent,
        WaterEconomyCalculatorComponent,
        Co2EconomyCalculatorComponent,
        SurfaceEconomyCalculatorComponent
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        NgbModule
    ],
    providers: [AuthService],
    bootstrap: [AppComponent]
})
export class AppModule {}
