
import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostBinding
} from '@angular/core';
import {
HttpClient,
} from '@angular/common/http';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import {ToastrService} from 'ngx-toastr';

import { ApiService } from '@services/api.service';
import { UsertriggerService } from '@services/usertrigger.service';

@Component({
  selector: 'app-usertriggeradd',
  templateUrl: './usertriggeradd.component.html',
  styleUrls: ['./usertriggeradd.component.scss']
})
export class UsertriggeraddComponent implements OnInit {

  

  public objNewForm: UntypedFormGroup;

  public isAuthLoading = false;
    

    submitted = false;
    errRegisterMsg = "";

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private api : ApiService,
        private usertrigger: UsertriggerService,
        private authService: AuthService,
        private appService: AppService
    ) {}

  ngOnInit(): void {

    this.objNewForm = new UntypedFormGroup({
      temperature: new UntypedFormControl(null, Validators.required),
      lightintensity: new UntypedFormControl(null, Validators.required) 
  });

  }

  onSubmit() {
    console.log(this.objNewForm.value);
 
    this.submitted = true;
        if (this.objNewForm.valid) {
          //get the account id , after - post data for new case
          this.api.getUserProfileAndInfo().subscribe((profileData: any[])=>{
             
            //this.profileData = profileData;
            console.log("PD");
            //console.log(profileData["internalID"]);
            //JSON.parse(profileData)
            //this.user = data;
            const internalID = profileData["internalID"]; 
            console.log(internalID);
            this.usertrigger.postObject(internalID,this.objNewForm.value.temperature,this.objNewForm.value.lightintensity).subscribe({
              next: data => {
                //this.router.navigateByUrl('/login');
                this.router.navigateByUrl('/usertrigger');
              },
              error: err => {
                //this.errRegisterMsg = err.error.error;
                this.router.navigateByUrl('/usertrigger');
              }
            });

          })
          this.router.navigateByUrl('/usertrigger');
        }
        else{
          this.toastr.error('Please specify required data', 'Invalid form');
        }
  }

}
