<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1">
            <div style="text-align: center!important">
                <img width="250px;" src="/assets/img/logogh.png">
            </div>
        </a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Register a new membership</p>
        <div *ngIf="errRegisterMsg" class="alert alert-danger" role="alert">
            {{errRegisterMsg}}
          </div>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="input-group mb-3">
                <input
                    formControlName="firstName"
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                />
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="lastName"
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                />
            </div>
            <div class="input-group mb-3">
                
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-7">
                    <div class="icheck-primary">
                        <!--
                        <input
                            type="checkbox"
                            id="agreeTerms"
                            name="terms"
                            value="agree"
                            required 
                        />
                        <label for="agreeTerms">
                            I agree to the <a href="#">terms</a>
                        </label>
                        <script>

                            document.getElementById("agreeTerms").setCustomValidity("Please indicate that you accept the Terms and Conditions");
                          
                          </script>
                          -->
                    </div>
                 
                </div>
                <!-- /.col -->
                <div class="col-5">
                    <app-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                    >
                        Register
                    </app-button>
                </div>
                <!-- /.col -->
            </div>
        </form>

        

        <a [routerLink]="['/login']" class="text-center">
            I already have a membership</a
        >
    </div>
</div>
