<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Watering</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Water dosages</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Water dosages specifications</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse">
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">

                <div class="row w-100">
                    <form [formGroup]="apiKeyForm" class="w-100">
                        <div class="row">
                            <div class="col">
                                <label> Rachio Api Key</label>
                                <div class="input-group mb-3">
                                    <input
                                        [readOnly]="isReadOnly()"
                                        formControlName="rachio"
                                        type="text"
                                        class="datepicker"
                                        class="form-control"
                                        placeholder="Start date"
                                        />
                                </div>
                            </div>
                            <div class="col-3">
                                <label> Select action</label>
                                <div class="btn-group input-group mb-3"
                                    role="group"
                                    aria-label="Basic example">
                                    <button *ngIf="rachio&&!editingRachio"
                                        (click)="toggleEdit()"
                                        type="button" class="btn
                                        btn-warning">Edit</button>
                                    <button *ngIf="!rachio" type="button"
                                    (click)="requestAdd()"
                                        class="btn
                                        btn-success">Add</button>

                                    <button *ngIf="rachio&&editingRachio"
                                        (click)="requestEdit()"
                                        type="button" class="btn
                                        btn-success">Save</button>
                                    <button *ngIf="rachio&&editingRachio"
                                        (click)=" toggleCancel()"
                                        type="button" class="btn
                                        btn-warning">Cancel</button>
                                    <button *ngIf="rachio&&editingRachio"
                                        (click)="requestClear()"
                                        type="button" class="btn
                                        btn-danger">Clear</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <div *ngFor="let dosage of this.objectsInfo"> <table
                        class="table table-responsive">
                        <tr class="w-100">
                            <th>Start Date</th>
                            <th>Start Hour</th>
                            <th>Days to run</th>
                            <th>Runs/day</th>
                            <th>Watering duration</th>




                        </tr>
                        <tr *ngFor="let stage of dosage.stages">
                            <td class="text-center">{{stage.startDate}}</td>
                            <td class="text-center">{{stage.startHour}}</td>
                            <td class="text-center">{{stage.daysAmmount}} days</td>
                            <td class="text-center">{{stage.dailyRuns}} times</td>
                            <td class="text-center">{{stage.dosageDuration}} min</td>
                        </tr>
                    </table>
                </div>



            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <div>
                    <button type="button" class="btn btn-primary btn-block"
                        style="width:150px;" id="export">Export as CSV</button>
                </div>
            </div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
